'use client'

import { List, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'

import Reference from './Reference'

interface ReferencesModalProps {
  documents: string[]
  googleDriveUrls?: string[]
}

const ReferencesModal: React.FC<ReferencesModalProps> = ({
  documents,
  googleDriveUrls,
}) => {
  const { t } = useTranslation()
  const { toggleModal, openModal } = useModalsState()

  return (
    <Modal
      centered
      open={openModal === 'references'}
      onCancel={() => toggleModal('references')}
      title={t('references')}
      footer={null}
      width={500}
    >
      <List
        size='small'
        dataSource={documents}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <Reference
              document={item}
              googleDriveUrl={
                googleDriveUrls
                  ? googleDriveUrls[index] !== ''
                    ? googleDriveUrls[index]
                    : undefined
                  : undefined
              }
              key={index}
            />
          </List.Item>
        )}
      />
    </Modal>
  )
}

export default ReferencesModal
